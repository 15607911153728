<template>
  <div class="popup-import-table">
    <p class="popup-import-table__title">Выберите записи для сохранения</p>
    <div class="table-container-base table-container-base--sort js-table-arrow">
      <thead>
        <tr>
          <th class="checkBoxColumn">
            <ComponentInput v-model="selectedAll" type="checkbox" />
          </th>
          <th class="sort width-192">Орган</th>
          <th class="sort width-192">Дата решения</th>
          <th class="sort width-192">Номер решения</th>
          <th class="sort width-192" v-if="!isSecond">Инициатор</th>
          <th class="sort width-192">Товар</th>
          <th class="sort width-192">Продление</th>
          <th class="sort width-192">Комментарии</th>
          <th class="sort width-192">Статус</th>
        </tr>
      </thead>
      <TableBody v-if="importData.changedItems?.length || importData.newItems?.length" :itemsBody="getItemsTable">
        <template v-if="importData.changedItems?.length">
          <tr>
            <th class="bold" colspan="10">Измененные записи</th>
          </tr>
          <tr v-for="item in importData.changedItems" :key="item.id">
            <td class="checkBoxColumn">
              <ComponentInput v-model="item.selected" type="checkbox" />
            </td>
            <td class="sort width-192">{{ item.organ.name }}</td>
            <td class="sort width-192">{{ $momentFormat(item.decisionDate, 'DD.MM.YYYY') }}</td>
            <td class="sort width-192">{{ item.decisionNumber }}</td>
            <td class="sort width-192" v-if="!isSecond">{{ item.initiator }}</td>
            <td class="sort width-192">{{ item.tnvedAlias }}</td>
            <td class="sort width-192">{{ item.prolongation }}</td>
            <td class="sort width-192">{{ item.comment }}</td>
            <td class="sort width-192">{{ item.isActive ? 'действ.' : 'не действ.' }}</td>
          </tr>
        </template>
        <template v-if="importData.newItems?.length">
          <tr>
            <th class="bold" colspan="10">Новые записи</th>
          </tr>
          <tr v-for="item in importData.newItems" :key="item.id">
            <td class="checkBoxColumn">
              <ComponentInput v-model="item.selected" type="checkbox" />
            </td>
            <td class="sort width-192">{{ item.organ.name }}</td>
            <td class="sort width-192">{{ $momentFormat(item.decisionDate, 'DD.MM.YYYY') }}</td>
            <td class="sort width-192">{{ item.decisionNumber }}</td>
            <td class="sort width-192" v-if="!isSecond">{{ item.initiator }}</td>
            <td class="sort width-192">{{ item.tnvedAlias }}</td>
            <td class="sort width-192">{{ item.prolongation }}</td>
            <td class="sort width-192">{{ item.comment }}</td>
            <td class="sort width-192">{{ item.isActive ? 'действ.' : 'не действ.' }}</td>
          </tr>
        </template>
      </TableBody>
    </div>
    <div class="popup-import-table__button">
      <ButtonComponent @click="save" mod="gradient-bg" class="button-redesign--popup-measure"
        >Сохранить</ButtonComponent
      >
      <ButtonComponent @click="saveAll" mod="gradient-bg" class="button-redesign--popup-measure"
        >Сохранить всё</ButtonComponent
      >
    </div>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import TableBody from '@/common/components/redesigned/TableBody';
  import Constants from '@/common/constants';

  import Api from '@/modules/measure/api/singlettr';

  export default {
    name: 'SingleTtrImportComponent',
    components: { ComponentInput, ButtonComponent, TableBody },
    props: {
      importData: {
        Type: Object,
        default: null,
      },
      isSecond: {
        Type: Boolean,
        default: false,
      },
      nn: Number,
    },
    emits: ['close'],
    data() {
      return {
        selectedAll: true,
      };
    },
    created() {
      this.loadPage();
    },
    computed: {
      getItemsTable() {
        return this.importData.changedItems.concat(this.importData.newItems);
      },
    },
    methods: {
      loadPage() {
        if (this.importData) {
          this.importData.changedItems.forEach((x) => (x.selected = true));
          this.importData.newItems.forEach((x) => (x.selected = true));
        }
      },
      save() {
        let items = [
          ...this.importData.changedItems.filter((x) => x.selected),
          ...this.importData.newItems.filter((x) => x.selected),
        ];
        if (items.length) this.uploadData(items);
      },
      saveAll() {
        let items = [...this.importData.changedItems, ...this.importData.newItems];
        if (items.length) this.uploadData(items);
      },
      uploadData(items) {
        Api.uploadSelectedData(items).then(() => {
          Constants.alert.fire('Импорт', 'Данные успешно загружены', 'success');
          this.$emit('close');
        });
      },
    },
    watch: {
      selectedAll(newValue) {
        if (this.importData) {
          if (this.importData.changedItems) {
            this.importData.changedItems.forEach((x) => (x.selected = newValue));
          }
          if (this.importData.newItems) {
            this.importData.newItems.forEach((x) => (x.selected = newValue));
          }
        }
      },
      nn() {
        this.loadPage();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
  @import '../styles/popup-import-table.scss';
</style>
